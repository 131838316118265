import * as yup from 'yup';
// import * as base64Utils from 'base-64';
import { setUserConfiguration } from 'dmpconnectjsapp-base/actions';
import moment from 'moment';
import { sendDocument } from '../../actions';
import { documentFormats, documentFormatsArray, documentFormatTypeCodes } from '../../constants';
import '../../utils/formUtils';
import { calculateVisibility }                            from '../../rules/documentRules';
import { b64EncodeUnicode } from '../../utils/dataUtils';

export const uploadType = {
  fileUpload: 'file_upload',
  plainTextUpload: 'plain_text_upload',
};

export const initialValues = {
  title: '',
  description: '',
  contribution: '',
  filename: '',
  base64: '',
  documentFormat: -1,
  formats: [],
  type: uploadType.fileUpload,
  patientHidden: false,
  guardianHidden: false,
  healthcareProfessionalHidden: false,
  healthcareSetting: '',
  typeCode: '',
  creationDate: moment().format('DD/MM/YYYY'),
  serviceStartDate: moment().format('DD/MM/YYYY'),
  serviceStopDate: moment().format('DD/MM/YYYY'),
  replaceDocumentId: null,
};

export const documentUploadSchema = (cumulPsPatient, manageMinor) => yup.object({
  title: yup.string().required('Ce champ est requis').DMPCMaxLength(128),
  description: yup.string().DMPCMaxLength(256),
  documentFormat: yup.mixed().when('type', {
    is: uploadType.fileUpload,
    then: () => yup.mixed().oneOf(documentFormatsArray, 'Ce champs est requis'),
    otherwise: () => yup.mixed(),
  }),
  typeCode: yup.mixed().when('documentFormat', {
    is: documentFormats.vsm,
    then: () => yup.mixed().oneOf(
      documentFormatTypeCodes[documentFormats.vsm],
      'Les fichiers VSM ne peuvent etre déposés que dans la catégorie Synthèse.',
    ),
    otherwise: () => yup.mixed().required('Ce champ est requis'),
  }),
  healthcareSetting: yup.string().required('Ce champ est requis'),
  contribution: yup.string().when('type', {
    is: type => type === uploadType.plainTextUpload,
    then: () => yup.string().required('Ce champs est requis'),
    otherwise: schema => schema,
  }),
  base64: yup.string().when('type', {
    is: type => type === uploadType.fileUpload,
    then: () => yup.string().required('Ce champs est requis'),
    otherwise: schema => schema,
  }),
  patientHidden: yup.bool().when('healthcareProfessionalHidden', {
    is: healthcareProfessionalHidden => healthcareProfessionalHidden && cumulPsPatient === 0,
    then: () => yup.bool().notOneOf([true]),
    otherwise: schema => schema,
  }),
  guardianHidden: yup.bool().when('healthcareProfessionalHidden', {
    is: healthcareProfessionalHidden => healthcareProfessionalHidden && manageMinor === 0,
    then: () => yup.bool().notOneOf([true]),
    otherwise: schema => schema,
  }),
  creationDate: yup.string().date().pastDate().required('Ce champs est requis'),
  serviceStartDate: yup.string().date().pastDate().required('Ce champs est requis'),
  serviceStopDate: yup.string().date().pastDate().required('Ce champs est requis'),
});

export const formatUploadDocumentParams = (values) => {
  let format = null;
  let data = null;

  const {
    base64,
    title,
    description,
    contribution,
    documentFormat,
    patientHidden,
    guardianHidden,
    healthcareProfessionalHidden,
    healthcareSetting,
    typeCode,
    type,
    replaceDocumentId,
    creationDate,
    serviceStartDate,
    serviceStopDate,
    versionNumber,
    setIdRoot,
    setIdExtension,
  } = values;

  if (type === uploadType.fileUpload) {
    format = documentFormat;
    data = base64;
  } else {
    format = documentFormats.textPlain;
    // data = base64Utils.encode(contribution);
    data = b64EncodeUnicode(contribution);
  }

  return {
    typeCode,
    data,
    title,
    description,
    format,
    documentVisibility: calculateVisibility(
      patientHidden,
      healthcareProfessionalHidden,
      guardianHidden,
      true,
    ),
    healthcareSetting,
    creationDate,
    serviceStartDate,
    serviceStopDate,
    replaceDocumentId,
    versionNumber,
    setIdRoot,
    setIdExtension,
  };
};

export const uploadDocument = (values, cpxInternalId, ins, dispatch) => {
  const {
    typeCode,
    data,
    title,
    description,
    format,
    documentVisibility,
    healthcareSetting,
    creationDate,
    replaceDocumentId,
    serviceStartDate,
    serviceStopDate,
  } = formatUploadDocumentParams(values);

  // garder healthcaresettings
  dispatch(setUserConfiguration(cpxInternalId, { healthcareSetting }));

  dispatch(sendDocument(
    ins,
    typeCode,
    data,
    title,
    description,
    format,
    documentVisibility,
    healthcareSetting,
    creationDate,
    replaceDocumentId,
    values.replaceDocumentUuid,
    serviceStartDate,
    serviceStopDate,
    Object.keys(values.certifiedIdentity || {}).length > 0 ? values.certifiedIdentity : undefined,
  ));
};
