import { setModalError }                                             from 'dmpconnectjsapp-base/actions';
import { dmpconnectActionConstants }                                 from 'dmpconnectjsapp-base/constants';
import { softwareErrors }                                            from 'dmpconnectjsapp-base/errors';
import { call, put, select, takeEvery }                              from 'redux-saga/effects';
import { routes }                                                    from '../../router';
import { createErrorDetails, createModalError, getErrorDescription } from '../errors';
import { errorActions }                                              from '../errors/errorActions';

import { errorTypes }                                 from '../errors/errorConfiguration';
import { getCurrentPathname, objectWithoutTheseKeys } from '../utils/dataUtils';
import { postMessageToIframeParent }                  from '../utils/iframe';

export const manageDmpconnectErrors = function * ({
  data: error, command, silentError, context,
}) {
  const errorDescription                   = getErrorDescription(error);
  const { i_apiErrorCode, i_apiErrorType } = error;
  const {
          timeout,
          longRunningTimeout,
          multipleCalls,
          ...commandParams
        }                                  = command;
  
  const sendErrorsToIframe = yield select(state => {
    const
      {
        dmpConnectPersistedAppConfiguration: {
          remoteSendErrorsToIframe,
        },
      } = state;
    return remoteSendErrorsToIframe;
  });
  
  if (sendErrorsToIframe) {
    postMessageToIframeParent({
      type: 'error',
      error,
      command,
      context,
    });
  }
  
  const { forceModal = false, forceAction } = context;
  
  const details = [
    createErrorDetails('Commande', commandParams),
  ];
  if (timeout || longRunningTimeout || multipleCalls) {
    const paramDetails = createErrorDetails('Paramètres commande', {
      commandTimeout                 : timeout,
      stillRunningNotificationTimeout: longRunningTimeout,
      allowMultiCalls                : multipleCalls,
    });
    details.push(paramDetails);
  }
  // si websocket timeout, ne pas afficher l'erreur détaillée
  if (!(i_apiErrorType === errorTypes.SoftwareErrors && i_apiErrorCode === softwareErrors.WEBSOCKET_TIMEOUT)) {
    details.push(createErrorDetails('Erreur détaillée', {
      detailsType: 'error',
      ...objectWithoutTheseKeys(error, ['customErrorMessage']),
    }));
  }
  
  const modalError       = createModalError(error, details);
  modalError.forceAction = forceAction;
  
  if (!forceAction && getCurrentPathname() === '/debug') {
    modalError.forceAction = errorActions.NONE;
  }
  
  if (errorDescription.errorHandler !== undefined) {
    console.log('errorHandler');
    yield call(errorDescription.errorHandler, {
      error, command, modalError, context, silentError,
    });
  } else if (
    (
      !silentError
      || forceModal
      || (i_apiErrorType === errorTypes.SoftwareErrors && i_apiErrorCode === softwareErrors.WEBSOCKET_ERROR)
    )
    && (
      errorDescription.forceModal === true
      || forceModal === true
      || errorDescription.modal === undefined
      || errorDescription.modal === true
    )
  ) {
    const pathname = yield select(getCurrentPathname);
    if (
      [
        routes.dmpConfig.path,
        routes.esConfig.path,
        routes.tseConfig.path,
        routes.vidalConfig.path,
        routes.logs.path,
        routes.pscInfo.path,
        routes.cpxConfig.path,
        '/authcallback',
        '/silent-sso',
      ].includes(pathname)
      && !(
      modalError.error.i_apiErrorCode === softwareErrors.CONNECTOR_REST_UNREACHABLE
      && modalError.error.i_apiErrorType === errorTypes.SoftwareErrors
      )
    ) {
      modalError.forceReconnect = true;
    }
    yield put(setModalError(modalError));
  }
};

export const handleDmpconnectErrors = function * () {
  yield takeEvery(dmpconnectActionConstants.DMPC_SYNCHRONOUS_COMMAND_FAILURE,
    manageDmpconnectErrors);
  yield takeEvery(dmpconnectActionConstants.DMPC_COMMAND_FAILURE, manageDmpconnectErrors);
};
