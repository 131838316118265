import React, { PureComponent } from 'react';
import { Form, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { apiSections, disabledOpCodes } from 'dmpconnectjsapp-base/constants';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from 'moment';
import UserSettingInput from '../../DMPConfiguration/Input/UserSettingInput';
import dmpTools from '../../../dmpconnect/tools/dmpTools';
import { uploadType } from '../../../dmpconnect/helpers/sendDocument';
import CategoryInput from './CategorieInput';
import InputDate from '../../Common/Form/Input/InputDate';
import Alert from '../../Common/Message/Alert';
import MobileListInput from '../../DMPConfiguration/Input/MobileListInput';

class DMPDocumentUploadForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fixedActStartEndDates: false,
    };
  }
  
  componentDidMount() {
    const {
            setFieldValue, healthcareSettings, values, secretConnection,
          } = this.props;
    
    if (secretConnection === true) {
      setFieldValue('guardianHidden', true);
    }
    if (healthcareSettings.find(choice => choice.code === values.healthcareSetting) === undefined) {
      setFieldValue('healthcareSetting', '');
    }
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { values, errors } = this.props;
    const { values: prevValues, errors: prevErrors } = prevProps;
    const { fixedActStartEndDates } = this.state;
    const momentDate = moment(values.creationDate, 'DD/MM/YYYY', true);
    
    if (
      !fixedActStartEndDates
      && momentDate.isValid()
      && (
        (!errors.creationDate && prevValues.creationDate !== values.creationDate)
        || (prevValues.creationDate === values.creationDate && !!prevErrors.creationDate && !errors.creationDate)
      )
    ) {
      const { setFieldValue } = this.props;
      setFieldValue('serviceStartDate', values.creationDate);
      setFieldValue('serviceStopDate', values.creationDate);
    }
  }
  
  handleFormatChange(format) {
    const { setFieldValue } = this.props;
    setFieldValue('documentFormat', parseInt(format, 10));
  }
  
  removeDocument() {
    const { setFieldValue, onPreviewClick } = this.props;
    onPreviewClick(false);
    setFieldValue('filename', '');
    setFieldValue('base64', '');
    setFieldValue('documentFormat', -1);
    setFieldValue('formats', []);
  }
  
  handleVisibilityToggle(values) {
    const { setFieldValue } = this.props;
    setFieldValue('patientHidden', values.includes('patient'));
    setFieldValue('healthcareProfessionalHidden', values.includes('ps'));
    setFieldValue('guardianHidden', values.includes('guardian'));
  }
  
  handleActDatesChange(event) {
    const { handleChange } = this.props;
    handleChange(event);
    this.setState({ fixedActStartEndDates: true });
  }
  
  render() {
    const {
            handleSubmit,
            handleChange,
            setFieldValue,
            values,
            errors,
            healthcareSettings,
            categories,
            i_cumulMasquePsPatient,
            i_minorFunctionManagement,
            secretConnection,
            noFileType,
            resetIns,
            onResetIns,
            isMobile,
          } = this.props;
    const { fixedActStartEndDates } = this.state;
    
    const isPatientInvalid = values.healthcareProfessionalHidden && i_cumulMasquePsPatient === 0;
    const isGuardianInvalid = secretConnection || (values.healthcareProfessionalHidden && i_minorFunctionManagement === 0);
    const isPATPSInvalid = values.patientHidden && i_cumulMasquePsPatient === 0;
    const isRLPSInvalid = (secretConnection || values.guardianHidden) && i_minorFunctionManagement === 0;
    
    const toggleValue = [];
    if (values.patientHidden) toggleValue.push('patient');
    if (values.healthcareProfessionalHidden) toggleValue.push('ps');
    if (values.guardianHidden) toggleValue.push('guardian');
    
    return (
      <>
        <Form onSubmit={handleSubmit}>
          {!isMobile && !noFileType && (
            <Form.Group controlId="Type" className="document-type-select">
              <ToggleButtonGroup
                type="radio"
                name="type"
                onChange={value => setFieldValue('type', value)}
                value={values.type}
              >
                <ToggleButton
                  size="sm"
                  className="mb-0 toggle-button"
                  variant="outline-secondary"
                  value={uploadType.fileUpload}
                >
                  <i className={isMobile ? 'ic-file-plus' : 'ic-document-file-small'} />
                  Fichier
                </ToggleButton>
                <ToggleButton
                  size="sm"
                  className="mb-0 toggle-button"
                  variant="outline-secondary"
                  value={uploadType.plainTextUpload}
                  onClick={() => this.removeDocument()}
                >
                  <i
                    className={isMobile ? 'ic-file-text' : 'ic-document-text-small'}
                    style={isMobile ? { fontSize: '0.8rem' } : undefined}
                  />
                  Texte
                </ToggleButton>
              </ToggleButtonGroup>
            </Form.Group>
          )}
          {!isMobile && resetIns && onResetIns && (
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                onClick={() => onResetIns()}
              >
                <i className="ic-edit" />
                Changer de DMP
              </button>
            </div>
          )}
          
          <Form.Group controlId="Titre">
            <Form.Label>
              Titre du document
            </Form.Label>
            <Form.Control
              placeholder="Titre du document"
              name="title"
              onChange={handleChange}
              value={values.title}
              isInvalid={!!errors.title}
              autoFocus
            />
            <Form.Control.Feedback type="invalid">
              {errors.title}
            </Form.Control.Feedback>
          </Form.Group>
          
          <Form.Group controlId="Description">
            <Form.Label>
              Description
            </Form.Label>
            <Form.Control
              as="textarea"
              rows="4"
              name="description"
              value={values.description}
              onChange={handleChange}
              isInvalid={!!errors.description}
            />
            <Form.Control.Feedback type="invalid">
              {errors.description}
            </Form.Control.Feedback>
          </Form.Group>
          
          <Form.Group controlId="Creation">
            <Form.Label>
              Date de création
            </Form.Label>
            <InputDate
              onChange={handleChange}
              value={values.creationDate}
              name="creationDate"
              label="Date de création"
              isValid={!errors.creationDate}
            />
            <Form.Control.Feedback type="invalid">
              {errors.creationDate}
            </Form.Control.Feedback>
          </Form.Group>
          
          <Form.Group controlId="Service">
            <Form.Label>
              Date de l&apos;acte médical
            </Form.Label>
            <Row>
              <Col>
                <Form.Group as={Row} controlId="serviceStart" className="align-items-center mb-0">
                  <Form.Label column sm="auto">
                    Début
                  </Form.Label>
                  <Col>
                    <InputDate
                      onChange={event => this.handleActDatesChange(event)}
                      onBlur={() => this.setState({ fixedActStartEndDates: true })}
                      value={fixedActStartEndDates ? values.serviceStartDate : ''}
                      name="serviceStartDate"
                      label="Date de début de l'acte médical"
                      isValid={!errors.serviceStartDate}
                      placeholder={values.serviceStartDate}
                    />
                  </Col>
                </Form.Group>
                {errors.serviceStartDate && (
                  <div className="d-block invalid-feedback">
                    {errors.serviceStartDate}
                  </div>
                )}
              </Col>
              <Col>
                <Form.Group as={Row} controlId="serviceStop" className="align-items-center mb-0">
                  <Form.Label column sm="auto">
                    Fin
                  </Form.Label>
                  <Col>
                    <InputDate
                      onChange={event => this.handleActDatesChange(event)}
                      onBlur={() => this.setState({ fixedActStartEndDates: true })}
                      value={fixedActStartEndDates ? values.serviceStopDate : ''}
                      name="serviceStopDate"
                      label="Date de fin de l'acte médical"
                      isValid={!errors.serviceStopDate}
                      placeholder={values.serviceStopDate}
                    />
                  </Col>
                  {errors.serviceStopDate && (
                    <div className="d-block invalid-feedback">
                      {errors.serviceStopDate}
                    </div>
                  )}
                </Form.Group>
              </Col>
            </Row>
          </Form.Group>
          
          <Form.Group controlId="Visibité">
            <Form.Label>
              Cacher aux :
            </Form.Label>
            <ToggleButtonGroup
              type="checkbox"
              value={toggleValue}
              onChange={visibilityValues => this.handleVisibilityToggle(visibilityValues)}
            >
              <ToggleButton
                className="toggle-button"
                size="sm"
                type="checkbox"
                value="patient"
                name="patientHidden"
                id="patientHidden_visibility_form"
                disabled={isPatientInvalid}
              >
                Patients
              </ToggleButton>
              <ToggleButton
                className="toggle-button"
                size="sm"
                type="checkbox"
                value="ps"
                name="healthcareProfessionalHidden"
                id="healthcareProfessionalHidden_visibility_form"
                disabled={isPATPSInvalid || isRLPSInvalid}
              >
                Professionnels de santé
              </ToggleButton>
              <ToggleButton
                className="toggle-button"
                size="sm"
                type="checkbox"
                value="guardian"
                name="guardianHidden"
                id="guardianHidden_visibility_form"
                disabled={isGuardianInvalid}
              >
                Représentants légaux
              </ToggleButton>
            </ToggleButtonGroup>
            {(isPatientInvalid || isPATPSInvalid) && (
              <Alert type="info" className="my-2 py-2">
                Le document ne peut être masqué au patient ET aux professionnels de santé
              </Alert>
            )}
            {(isGuardianInvalid || isRLPSInvalid) && (
              <Alert type="info" className="my-2 py-2">
                Le document ne peut être masqué aux représentants légaux ET aux professionnels de santé
              </Alert>
            )}
            {secretConnection === true && (
              <Alert type="info" className="my-2 py-2">
                Le document est automatiquement invisible au représentants légaux en connexion secrète
              </Alert>
            )}
          </Form.Group>
          
          <Form.Group controlId="typeCode">
            <Form.Label>
              Catégorie
            </Form.Label>
            {isMobile ? (
              <MobileListInput
                id="typeCode"
                name="typeCode"
                options={categories}
                grouped
                groupLabel="label"
                optionsKey="types"
                optionValue="code"
                optionLabel="label"
                onChange={handleChange}
                title="Sélectionner une catégorie"
              />
            ) : (
              <CategoryInput
                groupedCategories={categories}
                onChange={handleChange}
                name="typeCode"
                value={values.typeCode}
                error={errors.typeCode}
                isInvalid={!!errors.typeCode}
              />
            )}
          </Form.Group>
          
          <Form.Group controlId="healthcareSetting">
            <Form.Label>
              Cadre de soin
            </Form.Label>
            {isMobile ? (
              <MobileListInput
                id="healthcareSetting"
                name="healthcareSetting"
                options={dmpTools.getChoicesFiltered(healthcareSettings, disabledOpCodes)}
                optionValue="code"
                optionLabel="label"
                onChange={handleChange}
                title="Sélectionner un cadre de soin"
              />
            ) : (
              <UserSettingInput
                choices={dmpTools.getChoicesFiltered(healthcareSettings, disabledOpCodes)}
                onChange={handleChange}
                name="healthcareSetting"
                value={values.healthcareSetting}
                error={errors.healthcareSetting}
                isInvalid={!!errors.healthcareSetting}
              />
            )}
          </Form.Group>
        </Form>
      </>
    );
  }
}

DMPDocumentUploadForm.defaultProps = {
  errors: {},
  onPreviewClick: () => null,
  noFileType: false,
  resetIns: false,
  onResetIns: null,
  isMobile: null,
};

DMPDocumentUploadForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object,
  setFieldValue: PropTypes.func.isRequired,
  healthcareSettings: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  onPreviewClick: PropTypes.func,
  i_cumulMasquePsPatient: PropTypes.number.isRequired,
  i_minorFunctionManagement: PropTypes.number.isRequired,
  secretConnection: PropTypes.bool.isRequired,
  noFileType: PropTypes.bool,
  resetIns: PropTypes.bool,
  onResetIns: PropTypes.func,
  isMobile: PropTypes.bool,
};

function mapStateToProps(state) {
  const {
          dmpconnect: { secretConnection },
          dmpconnectInteropCodes: {
            [apiSections.DMP_PARAMETERS]: {
              i_cumulMasquePsPatient,
              i_minorFunctionManagement,
            },
          },
        } = state;
  return {
    i_cumulMasquePsPatient,
    i_minorFunctionManagement,
    secretConnection: secretConnection === 1,
  };
}
const ConnectedDMPUploadForm = connect(mapStateToProps)(DMPDocumentUploadForm);

export default ConnectedDMPUploadForm;
