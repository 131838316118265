import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
/* Middlewares */
import { routerMiddleware } from 'connected-react-router';
import thunkMiddleware from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';
import { persistStore } from 'redux-persist';
import createPersistReducer from './reducers';
import env from './envVariables';


export const history = createBrowserHistory();
export const sagaMiddleware = createSagaMiddleware();
// eslint-disable-next-line no-underscore-dangle
export const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({maxAge: 200}) : compose;
// export const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 }) : compose;

export default function configureStore() {
  let middlewares = [routerMiddleware(history), thunkMiddleware, sagaMiddleware];
  if (env.NODE_ENV !== 'production') {
    const loggerMiddleware = createLogger();
    middlewares = [...middlewares, loggerMiddleware];
  }

  const persistedReducer = createPersistReducer(history);

  const store = createStore(
    persistedReducer, // root reducer with router state
    {},
    composeEnhancers(
      applyMiddleware(
        ...middlewares,
      ),
    ),
  );
  const persistor = persistStore(store);
  return { store, persistor, persistedReducer };
}
